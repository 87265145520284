import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppInjector } from './modules/main/services/app-injector.service';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContentService } from 'services/content.service';
import { SiteConfigService } from 'services/site-config.service';

@NgModule({
	declarations: [AppComponent],
	imports: [
		// Core Modules
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		HttpClientJsonpModule,
		RouterModule,
		// 3rd-party Modules
		NgbModalModule,
		StoreModule.forRoot({
			router: routerReducer
		}),
		EffectsModule.forRoot([]),
		StoreRouterConnectingModule.forRoot(),
		StoreDevtoolsModule.instrument(),
		// App Modules
		AppRoutingModule,
		CoreModule
	],
	bootstrap: [AppComponent],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initializeAppCustomLogic,
			multi: true,
			deps: [SiteConfigService, ContentService],
		}
	]
})
export class AppModule {
	constructor(injector: Injector) {
		AppInjector.injector = injector;
	}
}

export function initializeAppCustomLogic(siteConfigService: SiteConfigService, contentService: ContentService): () => Promise<void> {
	return async () => {
		await siteConfigService.init();
		contentService.setupSiteLanguages(siteConfigService.site.languages ?? []);
	};
}
